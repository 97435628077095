import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Snackbar, Stack } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import dayjs from "dayjs";
import * as React from 'react';
import { useState } from "react";
import { MuiForm, MuiSelect, MuiTextField } from "react-mui-form-validator";

// const API_URL = "http://localhost:5001/api/v1/tickets/fake_create";
const API_URL = "http://localhost:5001/api/v1/tickets/create";
const SUCCESS_MSG = "Congrats! You have successfully created a ticket!"
const ERROR_MSG = "Something is wrong! Please check your inputs and try again later."

export default function MyForm() {
  // const [email, setEmail] = useState("");
  // const [itemNumber, setItemNumber] = useState("");
  // const [paidPrice, setPaidPrice] = useState("");
  // const [orderNumber, setOrderNumber] = useState("");
  const [email, setEmail] = useState("test@ab.com");
  const [itemNumber, setItemNumber] = useState("8888");
  const [paidPrice, setPaidPrice] = useState("888");
  const [orderNumber, setOrderNumber] = useState("999");
  const [quantity, setQuantity] = useState(1);
  const [orderDate, setOrderDate] = useState(dayjs());
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const changeEmail = (event) => {
    setEmail(event.target.value);
  };
  const changeItemNumber = (event) => {
    setItemNumber(event.target.value);
  };
  const changePaidPrice = (event) => {
    setPaidPrice(event.target.value);
  };
  const changeQuantity = (event) => {
    setQuantity(event.target.value);
  };
  const changeOrderNumber = (event) => {
    setOrderNumber(event.target.value);
  };
  const changeOrderDate = (value) => {
    setOrderDate(value);
  };

  const handleSubmit = () => {
    console.log(
      email,
      itemNumber,
      paidPrice,
      quantity,
      orderNumber,
      orderDate.format("YYYY-MM-DD"),
    );
    console.log(orderDate.format("YYYY-MM-DD"));
    console.log(`${API_URL}`);
    const option = {
      headers: { "X-API-Key": "jingshan" },
    };
    axios
      .post(
        `${API_URL}`,
        {
          email: email,
          itemNumber: itemNumber,
          paidPrice: paidPrice,
          quantity: quantity,
          orderNumber: orderNumber,
          orderDate: orderDate.format("YYYY-MM-DD"),
        },
        option,
      )
      .then(function (response) {
        console.log(response);

        setMessage(SUCCESS_MSG)
        setOpen(true);
        setOrderNumber("");
        setQuantity(1);
        setPaidPrice("");
        setItemNumber("");
        setEmail("");
        setOrderDate(dayjs());
      })
      .catch(function (error) {
        console.log(error);
        setMessage(ERROR_MSG)
        setOpen(true);
      });
  };

  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Box py={1} sx={{ bgcolor: "white" }}>
      <Typography
        variant="h5"
        gutterBottom
        color={"gray"}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        Creating your ticket
      </Typography>
      <MuiForm
        onSubmit={handleSubmit}
        onError={(errors) => console.log(errors)}
      >
        <Stack spacing={2} sx={{ mx: { xs: 4, md: 20, lg: 50, xl: 100 } }}>
          <MuiTextField
            name="email"
            label="Email"
            placeholder="Email"
            onChange={changeEmail}
            value={email}
            validators={["required", "isEmail"]}
            errorMessages={["this field is required", "email is not valid"]}
            fullWidth
          />
          <MuiTextField
            name="itemNumber"
            label="Item Number"
            placeholder="Item Number"
            onChange={changeItemNumber}
            value={itemNumber}
            validators={["required", "matchRegexp:^[0-9]*$"]}
            errorMessages={[
              "this field is required",
              "this field should be the item number",
            ]}
            fullWidth
          />
          <MuiTextField
            name="paidPrice"
            label="Paid Price"
            placeholder="Paid Price"
            onChange={changePaidPrice}
            value={paidPrice}
            validators={["required", "matchRegexp:^[0-9]+([.]?[0-9]+)?$"]}
            errorMessages={[
              "this field is required",
              "this field should be valid number",
            ]}
            fullWidth
          />
          <MuiSelect
            name="quantity"
            label="Quantity"
            placeholder="Quantity"
            onChange={changeQuantity}
            value={quantity}
            validators={["minNumber:1", "maxNumber:10"]}
            errorMessages={[
              "quantity cannot be less than 1",
              "quantity cannot be greater then 10",
            ]}
            fullWidth
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={10}>10</MenuItem>
          </MuiSelect>
          <MuiTextField
            name="orderNumber"
            label="Order Number"
            placeholder="Order Number"
            onChange={changeOrderNumber}
            value={orderNumber}
            validators={["matchRegexp:^[0-9]*$"]}
            errorMessages={["this field should be the order number"]}
            fullWidth
          />
          <DatePicker
            name="orderDate"
            label="Order Date"
            onChange={changeOrderDate}
            value={orderDate}
          />
          <Button type="submit" sx={{}} variant="outlined">
            Submit
          </Button>
          <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            message={message}
            action={action}
          ></Snackbar>
        </Stack>
      </MuiForm>
    </Box>
  );
}
