import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

function Footer() {
  return (
    <Box
      py={1}
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Typography color={"white"} variant="body" gutterBottom>
        Thanks for using our service!
      </Typography>
    </Box>
  );
}

export default Footer;
