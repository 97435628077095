import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

function MyHeader() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Typography color={"white"} variant="h3" gutterBottom m={1}>
        Costco savings
      </Typography>
    </Box>
  );
}

export default MyHeader;
