import MyHeader from "./MyHeader";
import Footer from "./Footer";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MyForm from "./MyForm";
import { Box } from "@mui/system";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ bgcolor: "lightblue"}}>
        <CssBaseline />
        <MyHeader />
        <MyForm />
        <Footer />
      </Box>
    </LocalizationProvider>
  );
}

export default App;
